import { useMemo } from 'react';
import { styled } from '@mui/material';
import {
  Avatar,
  Button,
  Card,
  Grid,
  Link,
  Typography
} from '@material-ui/core';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { getTranslatedList } from 'constants/constants';
import { getButtons } from 'constants/text/buttons';
import { getPlaceholders } from 'constants/text/placeholders';
import { getNameByVal } from 'helpers/listValues';
import useLocales from 'hooks/useLocales';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { updateSelectedCandidateStage } from 'requests/profile';
import { PATH_DASHBOARD } from 'routes/paths';
import useAuth from '@hooks/useAuth';
import { ButtonPrimary } from './new-designer/button/ButtonPrimary';
import { OverviewSelect } from './OverviewFilter';
import ScoreField from './ScoreField';

export const GridStyleContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'start',
  height: '100%'
}));

const CandidateCard = styled(Card)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '35px 20px',
  margin: 0,
  '@media (max-width: 992px)': {
    flexDirection: 'column',
    rowGap: '20px',
    div: {
      maxWidth: 'max-content !important'
    },
    '.MuiAvatar-root': {
      maxWidth: '124px !important'
    },
    '.MuiAutocomplete-root': {
      maxWidth: '100% !important',
      div: {
        maxWidth: '100% !important'
      }
    }
  }
}));

const GridButtons = styled(Grid)(() => ({
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'end',
  columnGap: '20px',
  '@media (max-width: 992px)': {
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

const GridProfile = styled(Grid)(() => ({
  alignItems: 'center',
  maxWidth: '500px',
  display: 'flex',
  flexWrap: 'nowrap',
  columnGap: '40px',
  '@media (max-width: 687px)': {
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center'
  }
}));

const CandidateLink = ({ id, name, avatar, avatarStyle }: any) => (
  <RouterLink
    to={PATH_DASHBOARD.candidates.getCandidateProfileUrl(id)}
    style={{ margin: '10px 0', display: 'flex', textDecoration: 'none' }}
  >
    <Avatar alt={name} src={avatar || ''} sx={{ mr: 1 }} style={avatarStyle} />
    <Typography variant="h4">{name}</Typography>
  </RouterLink>
);

const CardCandidateLow = ({
  selectedId,
  item,
  handleOpen,
  profile,
  scores,
  stage,
  setIsRefresh,
  isRefresh,
  onMoveCandidateToBlacklist,
  handleSelectCandidate
}: any) => {
  const { userId, user, isCandidate, isRecruiter, isHiringManager } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useLocales();
  const location = useLocation();
  const { pathname } = location;
  const {
    STAGE_TYPES,
    DEFAULT_MESSAGE_TEXTS: { error }
  } = getTranslatedList();
  const { ANONYMOUS } = getPlaceholders();
  const {
    APPLICANTS: {
      VIEW_CANDIDATE,
      SET_STAGE,
      SELECT_CANDIDATE,
      REMOVE_CANDIDATE
    }
  } = getButtons();

  const handleSetStage = async (value: any) => {
    const res = await updateSelectedCandidateStage(selectedId, value);
    if (res) {
      setIsRefresh(!isRefresh);
      const message = `${t('Candidate stage updated to')} "${getNameByVal(
        STAGE_TYPES,
        res.stage
      )}"`;
      enqueueSnackbar(message, { variant: 'success' });
    } else {
      enqueueSnackbar(error, {
        variant: 'error'
      });
    }
  };

  const isMatchProfileList = ['match-profile'].some((el) =>
    pathname.split('/').includes(el)
  );

  const candidate = item?.candidate ? item?.candidate : item;
  const candidateAvatar = candidate?.anonymous ? null : candidate?.avatar;
  const candidateName = candidate?.anonymous ? ANONYMOUS : candidate?.name;
  const isPageOverviewSelected =
    pathname === PATH_DASHBOARD.candidates.candidatesOverview;

  const isSelected = useMemo(
    () =>
      profile?.selected_candidates?.some(
        (el: any) => el.candidate.id === item.id
      ),
    [profile, item]
  );

  const isOwner = userId === item?.user;
  const ownedByBoundingRecruiter = user.recruiter === item?.user;

  const canTakeAction =
    (isHiringManager && ownedByBoundingRecruiter) ||
    (!isCandidate && !(isRecruiter && !isOwner));

  return (
    <CandidateCard sx={{ m: 1, width: '100%' }}>
      <GridProfile
        container
        style={{
          ...(isMatchProfileList ? { maxWidth: '600px' } : {})
        }}
      >
        <CandidateLink
          id={candidate?.id}
          avatar={candidateAvatar}
          avatarStyle={{
            ...(isMatchProfileList
              ? { width: '60px', height: '60px' }
              : { height: '124px', width: '124px' })
          }}
        />
        <Grid
          style={{
            ...(isMatchProfileList
              ? {
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '20px',
                  width: '100%',
                  justifyContent: 'space-between'
                }
              : {})
          }}
        >
          <Grid>
            {/* <Typography
             variant="caption"
             style={{
             marginBottom: '20px',
             fontWeight: 400,
             fontSize: '12px'
             }}
             >
             {fDate(item?.created_at)}
             </Typography> */}
            <Typography
              variant="h5"
              padding={0}
              style={{
                fontWeight: 700,
                fontSize: '20px'
              }}
            >
              {candidateName}
            </Typography>
            <Typography variant="h4" color="GrayText" padding={0}>
              {`Match for: ${profile?.name}`}
            </Typography>
          </Grid>
          <GridStyleContainer>
            <ScoreField
              score={item?.score || scores?.score}
              type="talent"
              fontSize={20}
              style={{ width: 'max-content' }}
            />
            <ScoreField
              score={item?.culture_score || scores?.culture_score}
              type="culture"
              fontSize={20}
              style={{ width: 'max-content' }}
            />
          </GridStyleContainer>
        </Grid>
      </GridProfile>
      <Grid style={{ display: 'flex', alignItems: 'center' }}>
        <Grid
          style={{
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'end'
          }}
        >
          <GridButtons
            style={{
              ...(isMatchProfileList
                ? { flexDirection: 'column', rowGap: '20px' }
                : {})
            }}
          >
            {isMatchProfileList
              ? !isSelected &&
                canTakeAction && (
                  <ButtonPrimary
                    variant="outlined"
                    fullWidth
                    onClick={(e: any) => {
                      e.preventDefault();
                      handleSelectCandidate &&
                        handleSelectCandidate(item.id, profile.id);
                    }}
                    style={{
                      background: isSelected ? '#d8d8e3' : 'none',
                      color: '#8059a9',
                      border: isSelected ? 'none' : '1px solid #8059a9'
                    }}
                  >
                    {SELECT_CANDIDATE}
                  </ButtonPrimary>
                )
              : canTakeAction && (
                  <OverviewSelect
                    width={180}
                    candidate={{ color: '#9B5FDA' }}
                    options={STAGE_TYPES}
                    activeOption={stage}
                    placeholder={SET_STAGE}
                    setOption={(value: any) => handleSetStage(value)}
                    styleAutocomplete={{
                      background: 'rgba(155, 95, 218, 0.2)',
                      borderRadius: '30px'
                    }}
                  />
                )}
            <Link
              style={{
                textDecoration: 'none',
                width: 'max-content',
                height: 'max-content'
              }}
              href={
                isPageOverviewSelected
                  ? PATH_DASHBOARD.candidates.getCandidateProfileUrl(
                      item?.candidate?.id
                    )
                  : PATH_DASHBOARD.candidates.getCandidateProfileUrl(
                      candidate?.id
                    )
              }
            >
              <Button
                variant="contained"
                sx={{ width: '100%', my: 3 }}
                style={{
                  background:
                    'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)',
                  borderRadius: '30px',
                  padding: '10px 20px',
                  margin: 0,
                  width: 'max-content'
                }}
              >
                <Typography>{VIEW_CANDIDATE}</Typography>
              </Button>
            </Link>
          </GridButtons>
          {(isSelected || selectedId) && canTakeAction && (
            <Button
              variant="outlined"
              sx={{
                padding: '10px 20px',
                color: '#FF6868',
                border: '1px solid #ff2727',
                fontWeight: 800,
                width: `${selectedId ? '50%' : '100%'}`,
                mt: `${selectedId ? '' : '20px'}`,
                borderRadius: 5,
                ':hover': {
                  background: '#ff3838',
                  color: '#fff',
                  borderColor: '#fff'
                }
              }}
              onClick={() => {
                onMoveCandidateToBlacklist(item?.id);
              }}
            >
              {REMOVE_CANDIDATE}
            </Button>
          )}
        </Grid>
        {handleOpen && (
          <KeyboardDoubleArrowRightIcon
            style={{
              fontSize: '30px',
              margin: '0 10px 0 20px',
              color: '#1C9DD7',
              cursor: 'pointer'
            }}
            onClick={() => handleOpen(item)}
          />
        )}
      </Grid>
    </CandidateCard>
  );
};

export default CardCandidateLow;
