import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CreateToDos, DeliteToDo, UpdateToDO, getToDoById, getToDosFiltered } from 'requests/todos';
import { AppDispatch, history, RootState } from '../store';
import { initialPaginated } from '../../constants/states';
import { enqueueSnackbar } from './notifications';
import { prepareUpdatedData } from 'helpers/listValues';

export const initialToDo = {
  title: '',
  description: '',
  completed: false,
  remind_me: 0
};

const initialState = {
  isLoading: false,
  mostViewedPeriod: 'monthly',
  ToDoPosts: initialPaginated,
  oneToDo: null,
  completed: false,
  editedToDo: initialToDo
};

const slice = createSlice({
  name: 'toDoSlice',
  initialState,
  reducers: {
    toDoLoading(state, action) {
      state.isLoading = action.payload;
    },
    ToDoPostsSuccess(state, action) {
      state.mostViewedPeriod = action.payload.periodicity;
      state.ToDoPosts = action.payload.ToDoPosts;
    },
    setToDoListSuccess(state, action) {
      state.ToDoPosts = action.payload;
    },
    setEditTodo(state, action) {
      state.editedToDo = action.payload
    },
    setOneTodo(state, action) {
      state.oneToDo = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getToDo.fulfilled, (state, action) => {
      state.oneToDo = action.payload;
    });
  }
});

export const setLoading = (isLoading: boolean) => (dispatch: AppDispatch) => {
  dispatch(slice.actions.toDoLoading(isLoading));
};

export const getToDos =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const { toDo } = getState();
    const { mostViewedPeriod } = toDo;

    dispatch(setLoading(true));
    try {
      if (!toDo.ToDoPosts.results)
        await dispatch(getToDoPostData(mostViewedPeriod));
    } catch {
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

export const createTodo =
  (data: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
    CreateToDos(data)
      .then((res) => {
        const { ToDoPosts } = getState().toDo;
        dispatch(
          slice.actions.setToDoListSuccess(
            prepareUpdatedData('create', ToDoPosts, res)
          )
        );
        dispatch(
          enqueueSnackbar({
            messageType: 'created',
            options: { variant: 'success' }
          })
        );
      })
      .catch(() =>
        dispatch(
          enqueueSnackbar({
            messageType: 'error',
            options: { variant: 'error' }
          })
        )
      );
  };

export const editDataTodo = (data: any) => async (dispatch: AppDispatch) => {
  dispatch(slice.actions.setEditTodo(data));
};

export const oneDataTodo = (data: any) => async (dispatch: AppDispatch) => {
  dispatch(slice.actions.setOneTodo(data));
};

export const updateTodo =
  (id: any, data?: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
    UpdateToDO(id, data)
      .then((res) => {
        const { ToDoPosts } = getState().toDo;
        dispatch(
          slice.actions.setToDoListSuccess(
            prepareUpdatedData('update', ToDoPosts, res)
          )
        );
      })
      .catch(() =>
        dispatch(
          enqueueSnackbar({
            messageType: 'error',
            options: { variant: 'error' }
          })
        )
      );
  };

export const deleteTodo =
  (id: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
    DeliteToDo(id)
      .then(() => {
        const { ToDoPosts } = getState().toDo;
        dispatch(
          slice.actions.setToDoListSuccess(
            prepareUpdatedData('delete', ToDoPosts, { id })
          )
        );
      })
      .then(() => {
        dispatch(
          enqueueSnackbar({
            messageType: 'deleted',
            options: { variant: 'success' }
          })
        );
      }).catch(() =>
        dispatch(
          enqueueSnackbar({
            messageType: 'error',
            options: { variant: 'error' }
          })
        )
      );
  };

export const getToDoPostData =
  (periodicity: string, limit?: number) => async (dispatch: AppDispatch) => {
    try {
      const ToDoPosts = await getToDosFiltered({
        periodicity,
        limit
      });
      dispatch(
        slice.actions.ToDoPostsSuccess({
          ToDoPosts,
          periodicity
        })
      );
    } catch {
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      );
    }
  };

export const getToDo = createAsyncThunk('job/getToDo', async (id: any) =>
  getToDoById(id).catch(() => history.push('/404'))
);

export default slice.reducer;
