import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  Typography
} from '@material-ui/core';

import { experimentalStyled as styled } from '@material-ui/core/styles';
import { useNavigate, NavLink as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  getComparedData,
  getCompetenciesScoresAndLabels,
  getNameByVal
} from 'helpers/listValues';
import { RootState } from 'redux/store';
import { getCardValuesByType } from 'helpers/cardValues';
import { HOST_URL, PATH_AUTH, PATH_HOME } from 'routes/paths';
import useLocales from 'hooks/useLocales';
import { getTexts } from 'constants/text/texts';
import useAuth from 'hooks/useAuth';
import { useState } from 'react';
import { SharedButtons } from './SharedButtons';
import { ApplyJobButton } from './ApplyJobButton';
import Logo from './Logo';
import CultureProfileCharts, { ChartDescription } from './CultureProfileCharts';
import { DeleteButton, EditButton } from './Buttons';
import { TestAssesmentButton } from './TestAssesmentButton';
import { Description5, Title5, Title6, TitlePrimary1 } from './text/title';
import { ButtonPrimary } from './new-designer/button/ButtonPrimary';
import CandidateModal from './CandidateModal';
import { SALARY_CHOICES_INR } from 'constants/constants';
import { fDateTimeBy } from 'utils/formatTime';
import ScoreField from './ScoreField';

export interface OpenJobPostType {
  created_at?: string;
  slug: string;
  id: number;
  title: string;
  studies: [{ id: number; name: string }];
  degree: number;
  work_exp: number;
  locations: any[];
  industries: any[];
  skills: any[];
  musts: any[];
  perks: any[];
  status: string;
  salary: number;
  contract: number;
  available: string;
  drivers_license: boolean;
  description: string;
  user: number;
  culture_profile: any;
  competency_labels: string[];
  competency_min_scores: number[];
  competencies: any[];
  view_count: number;
  is_applied?: boolean;
  assessment_required?: boolean;
  customer: any;
  user_name: string;
  culture_score: string;
  score: string;
}

export interface OpenJobPostProps {
  item: OpenJobPostType;
  onDelete: any;
  onEdit: any;
  editable?: boolean;
  compared?: any;
  nocustomer?: boolean;
}

const TitleTypography = styled(Typography)(() => ({
  fontFamily: 'Rubik',
  fontWeight: 600,
  fontSize: '13px',
  color: '#131417',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  lineHeight: '25px'
}));

const BoxStyle = styled(Box)(() => ({
  '@media (max-width: 768px)': {
    flexDirection: 'column',
    rowGap: '10px',
    '.MuiTypography-body1': {
      fontSize: '24px !important'
    },
    img: {
      width: '40px',
      height: '40px'
    }
  }
}));

const GridStyle = styled(Grid)(() => ({
  '@media (max-width: 768px)': {
    '.title': {
      fontSize: '20px'
    }
  }
}));

const CardFooterContainer = styled('div')(() => ({
  paddingTop: '0.5rem',
  marginTop: '0.5rem',
  display: 'flex',
  justifyContent: 'space-between',
  borderTop: '1px solid #e0e0e0',
  width: '100%'
}));

const CardStyles = styled(Card)((theme: any) => ({
  '&:hover': {
    ...(theme?.fromCandidate
      ? {
          boxShadow:
            '0 0 100px 0 rgb(145 158 171 / 24%), 0 0 100px 0 rgb(145 158 171 / 24%)'
        }
      : {})
  }
}));

const HrDivider = styled(Divider)(() => ({
  height: 0,
  border: '0.6px solid #C9CAD9'
}));

export const OpenJobPostCard = ({
  item,
  onDelete,
  onEdit,
  editable = true,
  compared = undefined,
  nocustomer = false
}: OpenJobPostProps) => {
  const [showModal, setShowModal] = useState(false);
  const [scoresTooltipIsOpen, setScoresTooltipIsOpen] = useState(false);
  const { mainInfo } = useSelector(
    (state: RootState) => state.candidate.candidate
  );
  const candidateCompetencies = mainInfo?.competencies || [];
  const cultureCompared = getComparedData(
    candidateCompetencies,
    item?.competencies
  );

  const navigate = useNavigate();
  const { t } = useLocales();
  const { getViewed } = getTexts();
  const { result: competencies }: any = useSelector(
    (state: RootState) => state.candidate.assessmentResult
  );

  const getScore = (com: any, cult: any, index: number) => {
    let score = '';
    if (com && com?.length) {
      score = com[index]?.score || com[index];
    } else if (cult && cult?.length) {
      score = cult[index]?.score || cult[index];
    }
    return score;
  };
  const labels: any = item.competency_labels;
  const points = item.competency_min_scores;
  let sortedPoints: any[];
  if (!labels?.[0]?.id) {
    sortedPoints =
      labels
        ?.map((el: string, i: number) => ({
          point: points[i],
          label: el,
          descriptions: '',
          comparePoint: getScore(compared, cultureCompared, i)
        }))
        ?.sort((a: any, b: any) => b.point - a.point) || [];
  } else {
    const sortedLabels = labels.sort((a: any, b: any) => b.id - a.id).reverse();
    const sortedInPoints: any = points
      .sort((a: any, b: any) => b.id - a.id)
      .reverse();
    sortedPoints =
      sortedLabels
        ?.map((el: any, i: number) => ({
          point: sortedInPoints[i].min_score,
          label: el.name,
          descriptions: '',
          comparePoint: getScore(compared, cultureCompared, i)
        }))
        ?.sort((a: any, b: any) => b.point - a.point) || [];
  }

  const { company }: any = useSelector(
    (state: RootState) => state.generalRecruiter
  );

  const {
    user,
    userId,
    isReviewer,
    isCandidate,
    isEmployee,
    isRecruiter,
    isHiringManager
  } = useAuth();
  const isCandidateOrEmployee = isCandidate || isEmployee;

  const isOwner = item.user === userId;

  const ownedByBoundingRecruiter = item.user === user.recruiter;

  const canTakeAction =
    !isReviewer ||
    (isRecruiter && isOwner) ||
    (isHiringManager && ownedByBoundingRecruiter);

  const onShowModal = () => {
    setShowModal(true);
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <CardStyles
        fromCandidate={mainInfo?.id}
        className="open-job-posts-card"
        sx={mainInfo?.id && { cursor: 'pointer' }}
        onClick={() => {
          mainInfo?.id && !scoresTooltipIsOpen && onShowModal();
        }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Link
                  onClick={onEdit}
                  sx={{
                    cursor: 'pointer',
                    width: '65%',
                    display: 'flex',
                    columnGap: '10px'
                  }}
                >
                  <TitleTypography
                    variant="h4"
                    style={{ textOverflow: 'ellipsis' }}
                  >
                    {item.title}{' '}
                  </TitleTypography>
                  <TitleTypography
                    variant="h4"
                    style={{ minWidth: 'max-content' }}
                  >
                    {!nocustomer && item?.customer && company?.is_agency
                      ? `[${item?.customer.name}]`
                      : ''}
                  </TitleTypography>
                </Link>
                <Typography variant="caption" color="GrayText">
                  {getViewed(item.view_count)}
                </Typography>
              </Box>
              <Grid
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: '10px'
                }}
              >
                {item.user_name && (
                  <TitleTypography style={{ textOverflow: 'ellipsis' }}>
                    {t('Created by')} {item.user_name}
                  </TitleTypography>
                )}
                <Typography
                  color="GrayText"
                  variant="caption"
                  style={{ textOverflow: 'ellipsis' }}
                >
                  {item?.created_at && fDateTimeBy(item?.created_at)}
                </Typography>
              </Grid>
              {(item.score || item.culture_score) && (
                <Grid
                  display="flex"
                  flexDirection="column"
                  mt={item.score ? '12px' : 0}
                >
                  {item.score && (
                    <ScoreField
                      score={item.score}
                      type="talent"
                      fontSize={20}
                    />
                  )}
                  {item.culture_score && (
                    <ScoreField
                      score={item.culture_score}
                      type="culture"
                      fontSize={20}
                    />
                  )}
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <HrDivider />
              {item.competency_labels?.length > 0 && (
                <CultureProfileCharts
                  title={t('Competencies Score')}
                  labels={item.competency_labels}
                  points={item.competency_min_scores}
                  comparePoints={compared || cultureCompared}
                  compareTitle="candidate"
                  descriptions={item.competencies.map((el: any) => ({
                    description: el.competency.description,
                    name: el.competency.name
                  }))}
                  scoresTooltipIsOpen={scoresTooltipIsOpen}
                  setScoresTooltipIsOpen={setScoresTooltipIsOpen}
                />
              )}
            </Grid>
          </Grid>
          {editable && (
            <CardFooterContainer>
              {canTakeAction && (
                <Box>
                  <EditButton onClick={onEdit} />
                  <DeleteButton onClick={onDelete} />
                </Box>
              )}
              <SharedButtons
                url={`${HOST_URL}${PATH_HOME.getJobUrl(item.slug)}`}
                title={item.title}
                description={item.description}
              />
            </CardFooterContainer>
          )}
          {!editable && item.assessment_required && !competencies && (
            <TestAssesmentButton />
          )}
          <Grid style={{ minHeight: editable ? '0' : '36px' }}>
            {(isCandidateOrEmployee && !item.assessment_required) ||
              (isCandidateOrEmployee && competencies && (
                <ApplyJobButton
                  jobPostId={item.id}
                  onApply={() => navigate(0)}
                  isApplied={!!item?.is_applied}
                />
              ))}
          </Grid>
        </CardContent>
      </CardStyles>
      {showModal && !isCandidateOrEmployee && !scoresTooltipIsOpen && (
        <CandidateModal
          open={showModal}
          handleClose={onCloseModal}
          candidate={mainInfo}
          profile={getCompetenciesScoresAndLabels(item?.competencies)}
          cultureProfile={getCompetenciesScoresAndLabels(
            item?.culture_profile?.competencies
          )}
          item={item}
        />
      )}
      <ChartDescription labels={sortedPoints} />
    </>
  );
};

export const SimpleJobCard = ({
  item,
  short = true,
  color
}: {
  item: any;
  short?: boolean;
  color: string;
}) => {
  const { t } = useLocales();
  const { logo }: any = useSelector(
    (state: RootState) => state.generalRecruiter.company
  );
  const company: any = useSelector(
    (state: RootState) => state.generalRecruiter.company
  );
  // const lng = localStorage.getItem('i18nextLng');
  const opt: any = useSelector((state: RootState) => state.fixturesReducer);
  const options = { ...opt };
  if (company.currency == 'INR') {
    options.salaries = SALARY_CHOICES_INR;
  }
  const fields = getCardValuesByType('job-post-questions', item, options);

  return (
    <Card
      style={{
        padding: '35px 50px',
        width: '100%',
        background: '#FFFFFF',
        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.08)',
        borderRadius: '25px',
        ...(!short && {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        })
      }}
    >
      <GridStyle>
        <BoxStyle style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Logo
            url={logo}
            sx={{
              height: '60px',
              p: 0
            }}
          />
          <TitlePrimary1 style={{ fontSize: '40px', color }}>
            {item.salary
              ? `${getNameByVal(options.salaries, item.salary)} ${
                  company.currency
                }`
              : `${getNameByVal(options.hourly_rates, item.hourly_rate)} ${
                  company.currency
                }${t('/Hour')}`}
          </TitlePrimary1>
        </BoxStyle>
        <Box>
          {!short ? (
            <Title6 className="title" style={{ margin: '20px 0', color }}>
              {item.title}
            </Title6>
          ) : (
            <Title5 className="title" style={{ margin: '20px 0', color }}>
              {item.title}
            </Title5>
          )}
          <Description5
            style={{
              ...(!short && {
                paddingBottom: '35px'
              })
            }}
            dangerouslySetInnerHTML={{
              __html: item.description
            }}
          />
        </Box>
        {short ||
          fields.map((el: any, index: number) => (
            <Box key={index}>
              <Title6
                className="title"
                sx={{ margin: '20px 0' }}
                style={{ color }}
              >
                {el.label}
              </Title6>
              <Description5
                sx={{
                  pb: '35px'
                }}
              >
                {el.value}
              </Description5>
            </Box>
          ))}
      </GridStyle>
      {!short && (
        <ButtonPrimary
          style={{
            maxWidth: 'max-content',
            background: `linear-gradient(92.25deg, ${color}90 -2.57%, ${color} 113.38%)`
          }}
          component={RouterLink}
          to={PATH_AUTH.register}
        >
          {t('apply for this job')}
        </ButtonPrimary>
      )}
    </Card>
  );
};
