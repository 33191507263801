import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Skeleton,
  Typography
} from '@material-ui/core';
import CultureProfileCharts, {
  ChartDescription
} from 'components/CultureProfileCharts';
import CandidateCardContainer from 'components/containers/CandidateCardContainer';
import { FilterGroup, OverviewFilter } from 'components/OverviewFilter';
import useAuth from 'hooks/useAuth';
import useLocales from 'hooks/useLocales';
import { RootState } from 'redux/store';
import {
  AssessmentResultType,
  CompetencyByGroupType,
  CompetencyByGroupTypeEN,
  CompetencyByGroupTypeNL
} from 'types/Assessment';
import { getAssessmentTest } from 'redux/slices/dialogs';
import { ButtonPrimary } from 'components/new-designer/button/ButtonPrimary';
import { getCandidateRemind } from 'requests/candidate';
import { ID } from 'requests';
import { useSnackbar } from 'notistack';
import { DEFAULT_MESSAGE_TEXTS } from 'constants/constants';
import { InfoButton } from '@components/Buttons';
import { ScoresTooltip } from '@components/ScoresTooltip';

export const CandidateAssessmentGraph = ({
  result,
  editable,
  iconSrc,
  id,
  status
}: {
  result: CompetencyByGroupTypeEN | CompetencyByGroupTypeNL | null;
  editable: boolean;
  iconSrc?: string;
  id: ID;
  status: number;
}) => {
  const { user, isAdmin, isRecruiterPlus, isRecruiter, isHiringManager } =
    useAuth();
  const [groups, setGroups] = useState<string[]>([]);
  const [group, setGroup] = useState<string>('');
  const [currentGroup, setCurrentGroup] = useState<any>(null);
  const [openTooltip, setOpenTooltip] = useState(false);
  const dispatch = useDispatch();
  const {
    assessmentResult,
    assessmentLoading
  }: {
    assessmentResult: AssessmentResultType;
    assessmentLoading: boolean;
  } = useSelector((state: RootState) => state.candidate);
  const { loading } = useSelector((state: RootState) => state.dialogs);
  const { t } = useLocales();
  const { enqueueSnackbar } = useSnackbar();

  const canRemindAssessment =
    isAdmin || isRecruiterPlus || isRecruiter || isHiringManager;

  useEffect(() => {
    const groups = Object.keys(result || {});
    setGroups(groups);
    setGroup(groups[0]);
  }, [result]);

  const groupsData =
    result && groups.length
      ? groups.map((group: string) => {
          const current = result[
            group as keyof typeof result
          ] as CompetencyByGroupType;
          return {
            groupTitle: group,
            list: current.lables.map((el: string, i: number) => ({
              title: el,
              description: current.descriptions[i],
              point: current.data[i]
            }))
          };
        })
      : [];

  const onTestingStart = async () => {
    if (user?.id) {
      dispatch(getAssessmentTest(user.id));
    }
  };

  useEffect(() => {
    if (result) {
      const current =
        (result[group as keyof typeof result] as CompetencyByGroupType) || null;
      setCurrentGroup(current);
    }
  }, [result, group]);

  if (assessmentLoading)
    return (
      <Skeleton variant="rectangular" height={300} sx={{ borderRadius: 1 }} />
    );

  const labels = currentGroup?.lables ?? [];
  const points = currentGroup?.data ?? [];
  const sortedPoints = !labels[0]?.id
    ? labels
        .map((label: any, i: number) => ({
          point: points[i],
          label,
          descriptions: '',
          comparePoint: []
        }))
        .sort((a: any, b: any) => b.point - a.point)
    : [];

  const handleRemind = async () => {
    const res = await getCandidateRemind(id);

    try {
      if (res) {
        enqueueSnackbar('Reminded successfully', { variant: 'success' });
      }
    } catch {
      enqueueSnackbar(DEFAULT_MESSAGE_TEXTS.error, { variant: 'error' });
    }
  };

  return (
    <CandidateCardContainer
      title={t('Assessment Graph')}
      groupsData={groupsData}
      iconSrc={iconSrc}
      infoIcon={<InfoButton onClick={() => setOpenTooltip(true)} />}
    >
      {result ? (
        <>
          <FilterGroup style={{ marginTop: '20px' }}>
            <OverviewFilter
              activeOption={group}
              options={groups}
              setOption={(value) => {
                if (value != group) {
                  setCurrentGroup(null);
                  setGroup(value);
                }
              }}
              style={{
                width: '100%'
              }}
            />
          </FilterGroup>

          <Grid style={{ maxWidth: '650px', margin: '0 auto' }}>
            <Card className="open-job-posts-card">
              <CultureProfileCharts
                descriptions={currentGroup?.descriptions}
                labels={currentGroup?.lables}
                points={currentGroup?.data}
                profile={true}
              />
            </Card>
            <ChartDescription labels={sortedPoints} />
          </Grid>
        </>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          {status !== 2 && editable && (
            <>
              <Typography variant="body1">
                {t("Seems like you haven't take our assessment test yet. Please take the test to apply for our jobs.")}
              </Typography>
              <Button
                sx={{ fontSize: 20, my: 2 }}
                size="large"
                variant="contained"
                onClick={onTestingStart}
                disabled={loading}
              >
                {!loading ? t('Start testing') : <CircularProgress />}
              </Button>
              <Typography variant="caption">
                *
                {t('Please wait a few minutes after passing the test so that we can update your profile.')}
              </Typography>
            </>
          )}
          {status !== 2 && !editable && (
            <Box>
              <Typography variant="body1">
                {t("Seems like this candidate hasn't take our assessment test yet.")}
              </Typography>
              {canRemindAssessment && (
                <ButtonPrimary
                  style={{
                    width: '100%',
                    borderRadius: '10px',
                    fontSize: '20px',
                    cursor: 'pointer',
                    marginTop: '10px'
                  }}
                  onClick={handleRemind}
                >
                  {t('Remind the candidate to take personality assessment')}
                </ButtonPrimary>
              )}
            </Box>
          )}
          {status === 2 && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                my: '20px'
              }}
            >
              <Typography variant="body1">
                {t('Result will be in shortly...')}
              </Typography>
            </Box>
          )}
        </Box>
      )}
      <ScoresTooltip open={openTooltip} onClose={() => setOpenTooltip(false)} />
    </CandidateCardContainer>
  );
};
