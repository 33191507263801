import { useEffect, useState } from 'react';
import { Box, Grid, makeStyles, Modal, Tabs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ToDoCard from 'components/ToDoCard';
import Page from 'components/containers/Page';
import ModalToDo from 'components/new-designer/modal-window/modalToDo';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  ButtonCheck,
  OverviewFilterType,
  OverviewSelect
} from 'components/OverviewFilter';
import { getTranslatedList } from 'constants/constants';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import PageContainer from 'components/containers/PageContainer';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteTodo,
  getToDo,
  getToDos,
  editDataTodo,
  initialToDo,
  oneDataTodo
} from 'redux/slices/toDo';
import { RootState } from 'redux/store';
import DashboardNavbar from '../../layouts/dashboard/navbar/DashboardNavbar';
import { ModalBox, ModalGrid, StyledIconButton } from '../../styled';
import { getNumber, getWeeksDiff } from '../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'nowrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    overflow: 'auto',
    maxWidth: '100%'
  },
  chip: {
    margin: theme.spacing(2)
  }
}));

const ScrollBar = ({
  options,
  setOption,
  activeOption,
  round = false
}: OverviewFilterType & { round?: boolean }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Tabs
        variant="scrollable"
        scrollButtons={true}
        aria-label="scrollable auto tabs example"
        ScrollButtonComponent={(props) => {
          let component = null;
          if (props.direction === 'left') {
            component = (
              <StyledIconButton isLeft {...props}>
                <ArrowBackIos />
              </StyledIconButton>
            );
          } else if (props.direction === 'right') {
            component = (
              <StyledIconButton {...props}>
                <ArrowForwardIos />
              </StyledIconButton>
            );
          }
          return component;
        }}
      >
        {options.map((option: any | string) => {
          const value = typeof option === 'string' ? option : option.value;
          const label = typeof option === 'string' ? value : option?.label;
          return (
            <ButtonCheck
              sx={{
                textTransform: 'capitalize'
              }}
              hover="5px"
              round={round}
              style={{
                ...(activeOption === value
                  ? {
                      background:
                        'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)',

                      color: '#FFFFFF',
                      padding: '10px 24px'
                    }
                  : {
                      background:
                        'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)'
                    }),
                ...(round
                  ? {
                      width: '57px',
                      height: '57px',
                      borderRadius: '50%'
                    }
                  : {
                      minWidth: '143px',
                      height: '57px',
                      borderRadius: '5px !important'
                    }),
                margin: '10px',
                boxShadow: '0px 4px 4px rgba(131, 33, 255, 0.1)',
                fontWeight: 700,
                fontSize: '14px'
              }}
              key={value}
              variant={activeOption === value ? 'contained' : 'text'}
              onClick={() => setOption(value)}
            >
              {label}
            </ButtonCheck>
          );
        })}
      </Tabs>
    </Box>
  );
};

export default function JobPoststoDoList() {
  const newYear = new Date().getFullYear();
  const { MONTH_TYPES } = getTranslatedList();
  const [activeYear, setActiveYear] = useState<string>(`${newYear}`);
  const [activeWeekly, setActiveWeekly] = useState<number>(1);
  const [toDoLIst, setToDoList] = useState<any>([]);
  const [edit, setEdit] = useState(false);
  const [modalData, setModalData] = useState<any>();
  const [openModal, setOpenModal] = useState<boolean>(true);
  const [activeMounth, setActiveMounth] = useState<string>(
    MONTH_TYPES[new Date().getMonth()].value
  );
  const [activeDay, setActiveDay] = useState<string>(`${new Date().getDate()}`);
  const { t } = useTranslation();

  const location = useLocation();

  const { pathname, search, hash } = location;

  const navigate = useNavigate();
  let name = new URLSearchParams(search).get('type');
  const MONTH_NUMBERS = getTranslatedList().MONTH_NUMBERS as any;
  const dispatch = useDispatch();
  const {
    ToDoPosts: { results },
    mostViewedPeriod,
    oneToDo,
    editedToDo
  }: any = useSelector((state: RootState) => state.toDo);
  const { id } = useParams<any>();
  const router = useNavigate();

  useEffect(() => {
    if (hash && !name) {
      const hashParts = hash?.split('-');
      const lastHashValue = hashParts[hashParts.length - 1];
      name = lastHashValue;
    }
  }, [router]);

  const daysCount = new Date(
    Number(activeYear),
    MONTH_NUMBERS[activeMounth],
    0
  ).getDate();

  const DAILY_TYPE = [];

  for (let i = 1; i <= daysCount; i++) {
    DAILY_TYPE.push({ value: `${i}`, label: `${getNumber(i)}` });
  }
  const YEAR_TYPES = [];
  for (let i = newYear; i > newYear - 10; i--) {
    YEAR_TYPES.push({ value: `${i}`, label: `${getNumber(i)}` });
  }

  const weeklyCount = getWeeksDiff(
    new Date(`${activeMounth} 1 ${activeYear}`),
    new Date(`${activeMounth} ${daysCount} ${activeYear}`)
  );

  const WEEKLY_TYPE = [];
  for (let i = 1; i <= weeklyCount; i++) {
    WEEKLY_TYPE.push({ value: `${i}`, label: `${getNumber(i)}` });
  }

  const editToDo = (ToDoid: any) => {
    const findData = toDoLIst.find((item: any) => item.id == ToDoid);
    dispatch(editDataTodo(findData));
    dispatch(oneDataTodo(findData));
    setModalData(findData);
  };

  const deliteFild = async (ToDoid: any) => {
    setModalData(null);
    setEdit(false);
    dispatch(deleteTodo(Number(ToDoid)));

    if (oneToDo) {
      const newPath = pathname.replace(/\d+/g, '');
      navigate(newPath + location.search, { replace: true });
    }
  };

  useEffect(() => {
    dispatch(getToDos());
  }, [mostViewedPeriod]);

  const getTodoList = () => {
    const filteredData = results?.filter((item: any) => {
      const itemYear = new Date(item.created_at).getFullYear();
      const itemMonth = new Date(item.created_at).getMonth();

      if (activeYear && itemYear !== Number(activeYear)) {
        return false;
      }

      if (
        name !== 'yearly' &&
        activeMounth &&
        MONTH_TYPES[itemMonth].value !== activeMounth
      ) {
        return false;
      }

      if (name === 'weekly' && activeMounth && activeYear) {
        const weeksDiff = getWeeksDiff(
          new Date(`${activeMounth} 1 ${activeYear}`),
          new Date(item.created_at)
        );
        if (weeksDiff !== Number(activeWeekly)) {
          return false;
        }
      }

      if (name === 'daily' && activeMounth && activeYear) {
        const itemDay = new Date(item.created_at).getDate();
        if (itemDay !== Number(activeDay)) {
          return false;
        }
      }

      return true;
    });

    setToDoList(filteredData || []);
  };

  useEffect(() => {
    getTodoList();
  }, [activeDay, activeMounth, activeYear, activeWeekly, results]);

  useEffect(() => {
    if (id) {
      dispatch(getToDo(id));
    }
  }, [id]);

  useEffect(() => {
    dispatch(getToDos());
  }, []);

  useEffect(() => {
    const deletedElement = results?.some((el: any) => el.id === Number(id));
    setOpenModal(deletedElement);
  }, [results, id]);

  return (
    <Page>
      <DashboardNavbar title={t('Todos')} />
      <PageContainer>
        <Grid
          style={{
            marginTop: '20px'
          }}
        >
          <Grid
            style={{
              width: '100%',
              display: 'flex',
              columnGap: '20px',
              justifyContent: 'center'
            }}
          >
            {name !== 'yearly' && (
              <>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    columnGap: '10px'
                  }}
                >
                  <OverviewSelect
                    todo
                    width={150}
                    options={YEAR_TYPES}
                    setOption={(value: any) => {
                      setActiveYear(value);
                    }}
                    activeOption={activeYear}
                  />
                  {name !== 'monthly' && (
                    <OverviewSelect
                      todo
                      width={200}
                      options={MONTH_TYPES}
                      setOption={(value: any) => {
                        setActiveMounth(value);
                      }}
                      activeOption={activeMounth}
                    />
                  )}
                </Box>
              </>
            )}
          </Grid>
          <Grid style={{ position: 'relative', padding: '0 50px' }}>
            {name === 'daily' && activeMounth && activeYear && (
              <ScrollBar
                options={DAILY_TYPE}
                round={true}
                setOption={(value: any) => {
                  setActiveDay(value);
                }}
                activeOption={activeDay}
              />
            )}
            {name === 'monthly' && (
              <ScrollBar
                options={MONTH_TYPES}
                setOption={(value: any) => {
                  setActiveMounth(value);
                }}
                activeOption={activeMounth}
              />
            )}
            {name === 'yearly' && (
              <ScrollBar
                options={YEAR_TYPES}
                setOption={(value: any) => {
                  setActiveYear(value);
                }}
                activeOption={activeYear}
              />
            )}
            {name === 'weekly' && activeYear && activeMounth && (
              <ScrollBar
                options={WEEKLY_TYPE}
                setOption={(value: any) => {
                  setActiveWeekly(value);
                }}
                activeOption={activeWeekly}
              />
            )}
          </Grid>
          <Grid
            container
            style={{
              justifyContent: 'space-between',
              rowGap: '20px'
            }}
          >
            <ToDoCard
              addNew={true}
              setEdit={(val: boolean) => {
                dispatch(editDataTodo(initialToDo));
                setEdit(val);
              }}
            />
            {toDoLIst?.map((toDoLIstItem: any, index: number) => {
              const ToDoid = toDoLIstItem.id;
              return (
                <ToDoCard
                  toDoLIstItem={toDoLIstItem}
                  ToDoid={ToDoid}
                  editToDo={(val: any) => {
                    editToDo(val);
                    setEdit(true);
                  }}
                  deliteFild={deliteFild}
                  key={index}
                />
              );
            })}
            {oneToDo && (
              <Modal open={openModal}>
                <ModalGrid>
                  <ModalBox>
                    <ToDoCard
                      toDoLIstItem={oneToDo}
                      ToDoid={id}
                      editToDo={(val: any) => {
                        editToDo(val);
                        setEdit(true);
                      }}
                      deliteFild={deliteFild}
                      width="100%"
                      height="100%"
                      setModalData={setModalData}
                      setEdit={(val: boolean) => {
                        editToDo(id);
                        setEdit(true);
                      }}
                      modal={openModal}
                    />
                  </ModalBox>
                </ModalGrid>
              </Modal>
            )}
            <Modal
              open={edit && editedToDo}
              onClose={() => {
                setEdit(false);
                setModalData(null);
              }}
            >
              <ModalGrid>
                <ModalBox>
                  {editedToDo ? (
                    <ModalToDo
                      title={editedToDo.title}
                      description={editedToDo.description}
                      listId={editedToDo.id}
                      completed={editedToDo.completed}
                      close={() => {
                        setEdit(false);
                        setModalData(null);
                      }}
                      periodicity={mostViewedPeriod}
                    />
                  ) : (
                    <ModalToDo
                      close={() => {
                        setEdit(false);
                        setModalData(null);
                      }}
                      periodicity={mostViewedPeriod}
                    />
                  )}
                </ModalBox>
              </ModalGrid>
            </Modal>
          </Grid>
        </Grid>
      </PageContainer>
    </Page>
  );
}
