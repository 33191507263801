import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '@hooks/useAuth';
import PaginationContainer from 'components/containers/PaginationContainer';
import { setMatchCandidatesFilter } from 'redux/slices/filters';
import FileUploader from 'components/FileUploader';
import { resetDataFromJobFile } from 'redux/slices/jobSlice';
import { UploadJobFileToAI } from 'functions';
import { useSnackbar } from 'notistack';
import AIModal from 'components/AIModal';
import { RootState } from '../../redux/store';
import { getMatchProfiles } from '../../redux/slices/generalRecruiter';
import Page from '../../components/containers/Page';
import { PATH_DASHBOARD } from '../../routes/paths';
import { ProfileCard } from '../../components/CardProfile';
import PageContainer from '../../components/containers/PageContainer';
import ListContainer from '../../components/containers/ListContainer';
import HeadingContainer from '../../components/containers/HeadingContainer';
import useLocales from '../../hooks/useLocales';
import DashboardNavbar from '../../layouts/dashboard/navbar/DashboardNavbar';

const FindCandidates = ({ style = {} }) => {
  const { isAdmin, isRecruiterPlus, isRecruiter } = useAuth();
  const [showUploader, setShowUploader] = useState(true);
  const [openAIPopUp, setOpenAIPopUp] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const matchProfiles = useSelector(
    (state: RootState) => state.generalRecruiter.matchProfiles
  );
  const filters = useSelector(
    (state: RootState) => state.filtersReducer.matchCandidatesOverview
  );
  const { page, limit, offset } = filters;

  const { t } = useLocales();
  const { results, count } = matchProfiles;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getMatchProfiles(limit, offset));
  }, [dispatch, limit, offset]);

  useEffect(() => {
    dispatch(setMatchCandidatesFilter('offset', page * limit - limit));
  }, [dispatch, page, limit]);
  const onCreateNew = () => {
    navigate(PATH_DASHBOARD.candidates.getMatchProfileUrl('new'));
  };

  const handleUploadJobFile = (file: any) => {
    dispatch(resetDataFromJobFile());
    UploadJobFileToAI({
      file,
      path: '/candidates/find-candidates/new/',
      setShowUploader,
      setOpenAIPopUp,
      enqueueSnackbar,
      navigate,
      dispatch,
      translate: t
    });
  };

  const title = t('Find Candidates');

  const canTakeAction = isAdmin || isRecruiterPlus || isRecruiter;

  return (
    <Page title={title}>
      <DashboardNavbar title={title} />
      <PageContainer style={style}>
        <HeadingContainer
          subtitle={t('Manage your match profile list and search candidates')}
          actionButtons={
            canTakeAction
              ? [
                  <Button
                    key="create-new"
                    onClick={onCreateNew}
                    variant="contained"
                  >
                    {t('Create new match profile')}
                  </Button>,
                  showUploader && (
                    <FileUploader
                      key="upload-job-file"
                      type="file"
                      variant="contained"
                      onSubmit={handleUploadJobFile}
                      style={{ height: '100%', padding: '10px 45px' }}
                    >
                      {t('Upload Job File')}
                    </FileUploader>
                  )
                ]
              : []
          }
        />
        <AIModal open={openAIPopUp} onClose={() => setOpenAIPopUp(false)} />
        <ListContainer
          list={results}
          card={ProfileCard}
          getCardProps={() => ({
            type: 'match'
          })}
          gridProps={{ sm: 6, lg: 4 }}
        />
        <PaginationContainer
          page={page}
          count={count}
          perPage={limit}
          setPage={(value) => {
            dispatch(setMatchCandidatesFilter('page', value));
          }}
        />
      </PageContainer>
    </Page>
  );
};

export default FindCandidates;
