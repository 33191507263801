import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@material-ui/core';

import { RootState } from 'redux/store';
import { getCultureProfiles } from 'redux/slices/generalRecruiter';
import { PATH_DASHBOARD } from 'routes/paths';

import { ProfileCard } from 'components/CardProfile';
import HeadingContainer from 'components/containers/HeadingContainer';
import Page from 'components/containers/Page';
import PaginationContainer from 'components/containers/PaginationContainer';
import ListContainer from 'components/containers/ListContainer';
import PageContainer from 'components/containers/PageContainer';
import useLocales from 'hooks/useLocales';
import { setCultureProfilesFilter } from 'redux/slices/filters';
import useAuth from '@hooks/useAuth';
import DashboardNavbar from '../../layouts/dashboard/navbar/DashboardNavbar';

const CultureProfiles = () => {
  const { isReviewer, isHiringManager } = useAuth();
  const { cultureProfiles, cultureProfilesLoading }: any = useSelector(
    (state: RootState) => state.generalRecruiter
  );
  const filters = useSelector(
    (state: RootState) => state.filtersReducer.cultureProfiles
  );
  const { results, count } = cultureProfiles;
  const { page, limit, offset } = filters;
  const targetRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useLocales();

  useEffect(() => {
    dispatch(getCultureProfiles(limit, offset));
  }, [dispatch, limit, offset]);

  useEffect(() => {
    dispatch(setCultureProfilesFilter('offset', page * limit - limit));
  }, [dispatch, page, limit]);

  const onCreateNew = () =>
    navigate(PATH_DASHBOARD.culture_profile.getCultureProfileUrl('new'));

  const handleChangeScroll = (): void => {
    if (targetRef?.current) {
      targetRef.current.scrollIntoView({
        block: 'start'
      });
    }
  };

  const title = t('Culture Profiles');
  return (
    <Page title={title}>
      <PageContainer>
        <DashboardNavbar title={title} />
        {!(isReviewer || isHiringManager) && (
          <Box ref={targetRef}>
            <HeadingContainer
              subtitle={t('Here you can a create list of culture profiles for your specific teams')}
              actionButton={
                <Button onClick={onCreateNew} variant="contained">
                  {t('Create new culture profile')}
                </Button>
              }
            />
          </Box>
        )}
        <ListContainer
          loading={cultureProfilesLoading}
          list={results}
          card={ProfileCard}
          getCardProps={() => ({ type: 'culture' })}
          gridProps={{ sm: 6, lg: 4 }}
        />
        <PaginationContainer
          page={page}
          count={count}
          perPage={limit}
          setPage={(value) => {
            handleChangeScroll();
            dispatch(setCultureProfilesFilter('page', value));
          }}
        />
      </PageContainer>
    </Page>
  );
};

export default CultureProfiles;
