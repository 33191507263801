/* eslint-disable react-hooks/rules-of-hooks */
// routes
import { TFunction } from 'react-i18next';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { getNavbarIcon } from '../../../utils/getIcon';

// ----------------------------------------------------------------------

const ICONS = {
  settings: getNavbarIcon('ic_user'),
  settingsDis: getNavbarIcon('ic_user'),
  user_settings: getNavbarIcon('user_settings_dis'),
  user_settingsDis: getNavbarIcon('user_settings'),
  dashboard: getNavbarIcon('ic_dashboardNew'),
  dashboardDis: getNavbarIcon('disabledDashboard'),
  candidatesDis: getNavbarIcon('disabledCandidates'),
  companyDis: getNavbarIcon('disabledCompany'),
  jobPostDis: getNavbarIcon('disabledJobPosts'),
  campaigns: getNavbarIcon('campaigns'),
  campaignsDisable: getNavbarIcon('campaignsDisable'),
  company: getNavbarIcon('company_m'),
  jobPost: getNavbarIcon('Job_m'),
  candidates: getNavbarIcon('candidate_m'),
  dailyApplicants: getNavbarIcon('candidate_m'),
  openJobposts: getNavbarIcon('ic_ecommerce'),
  managementInsights: getNavbarIcon('ic_editor'),
  mostViewedJobPosts: getNavbarIcon('ic_blog'),
  matchProfile: getNavbarIcon('ic_components'),
  cultureProfile: getNavbarIcon('ic_analytics'),
  createJobPost: getNavbarIcon('ic_copy'),
  cultureProfileDashboard: getNavbarIcon('culture_dashboard'),
  cultureProfileDashboardDisable: getNavbarIcon('culture_dashboard_disable'),
  dataAnalytics: getNavbarIcon('dataAnalytics'),
  dataAnalyticsDis: getNavbarIcon('dataAnalyticsDis'),
  messengerIcon: getNavbarIcon('message-icon'),
  messengerActiveIcon: getNavbarIcon('message-active-icon'),
  todoActiveIcon: getNavbarIcon('todo-active-icon'),
  todoIcon: getNavbarIcon('todo-icon'),
  scheduleIcon: getNavbarIcon('schedule'),
  scheduleActive: getNavbarIcon('schedule-active'),
  templatesActiveIcon: getNavbarIcon('templates-active-icon'),
  templatesIcon: getNavbarIcon('templates-icon')
};

export const adminItems = (t: TFunction<'translation'>, is_agency: any) => [
  {
    subheader: t('Management'),
    items: [
      {
        title: t('Dashboard'),
        href: PATH_DASHBOARD.showcase,
        icon: ICONS.dashboard,
        iconDisabled: ICONS.dashboardDis
      }
    ]
  },
  {
    subheader: t('Company'),
    items: [
      {
        title: t('Company'),
        href: PATH_DASHBOARD.company.root,
        icon: ICONS.company,
        iconDisabled: ICONS.companyDis,
        openState: false,

        items: [
          {
            title: t('Account Settings'),
            href: PATH_DASHBOARD.company.settings,
            icon: ICONS.settings
          },
          {
            title: t('Site settings'),
            href: PATH_DASHBOARD.company.cmsSetting,
            icon: ICONS.settings
          },
          {
            title: t('Integrations'),
            href: PATH_DASHBOARD.company.integrations,
            icon: ICONS.settings
          }
        ]
      }
    ]
  },
  {
    subheader: t('Culture'),
    items: [
      {
        title: t('Culture Profiles'),
        href: PATH_DASHBOARD.culture_profile.cultureProfiles,
        icon: ICONS.cultureProfileDashboard,
        iconDisabled: ICONS.cultureProfileDashboardDisable
      }
    ]
  },
  {
    subheader: t('Job Posts'),
    items: [
      {
        title: t('Job Posts'),
        href: PATH_DASHBOARD.jobPosts.root,
        icon: ICONS.jobPost,
        iconDisabled: ICONS.jobPostDis,
        openState: false
      }
    ]
  },
  {
    subheader: t('Candidates'),

    items: [
      {
        title: t('Candidates'),
        href: PATH_DASHBOARD.candidates.root,
        icon: ICONS.candidates,
        iconDisabled: ICONS.candidatesDis,

        openState: false
      }
    ]
  },
  {
    subheader: t('Calendar'),
    items: [
      {
        title: t('Calendar'),
        href: PATH_DASHBOARD.calendar.root,
        icon: ICONS.scheduleActive,
        iconDisabled: ICONS.scheduleIcon
      }
    ]
  },
  {
    subheader: t('Messenger'),
    items: [
      {
        title: t('Messenger'),
        href: PATH_DASHBOARD.messenger.root,
        icon: ICONS.messengerActiveIcon,
        iconDisabled: ICONS.messengerIcon
      }
    ]
  },
  {
    subheader: t('Data Analytics'),
    items: [
      {
        title: t('Data Analytics'),
        href: PATH_DASHBOARD.dataAnalytics.root,
        icon: ICONS.dataAnalytics,
        iconDisabled: ICONS.dataAnalyticsDis
      }
    ]
  },
  {
    subheader: t('Todos'),
    items: [
      {
        title: t('Todos'),
        href: PATH_DASHBOARD.toDoList.getmostViewedPeriod('monthly'),
        icon: ICONS.todoActiveIcon,
        iconDisabled: ICONS.todoIcon
      }
    ]
  },
  {
    subheader: t('Templates'),
    items: [
      {
        title: t('Templates'),
        href: PATH_DASHBOARD.templates.root,
        icon: ICONS.templatesActiveIcon,
        iconDisabled: ICONS.templatesIcon
      }
    ]
  },
  ...(is_agency
    ? [
        {
          subheader: t('Customers'),
          items: [
            {
              title: t('Customers'),
              href: PATH_DASHBOARD.CUSTOMERS.root,
              icon: ICONS.dataAnalytics,
              iconDisabled: ICONS.dataAnalyticsDis
            }
          ]
        }
      ]
    : [])
];

/// //

export const candidateItems = (t: TFunction<'translation'>) => [
  {
    subheader: t('Management'),
    items: [
      {
        title: t('My profile'),
        href: PATH_DASHBOARD.candidates.getCandidateProfileUrl('me'),
        icon: ICONS.candidates,
        iconDisabled: ICONS.candidatesDis
      }
    ]
  },
  {
    subheader: t('Vacancies'),
    items: [
      {
        title: t('Job Posts'),
        href: PATH_DASHBOARD.jobPosts.root,
        icon: ICONS.jobPost,
        iconDisabled: ICONS.jobPostDis,
        items: [
          {
            title: t('Job Posts Overview'),
            href: PATH_DASHBOARD.jobPosts.jobPostsOverview,
            icon: ICONS.openJobposts
          },
          {
            title: t('My Applications'),
            href: PATH_DASHBOARD.candidates.applicantsOverview,
            icon: ICONS.dailyApplicants
          }
        ]
      }
    ]
  },
  {
    subheader: t('Calendar'),
    items: [
      {
        title: t('Calendar'),
        href: PATH_DASHBOARD.calendar.root,
        icon: ICONS.scheduleActive,
        iconDisabled: ICONS.scheduleIcon
      }
    ]
  }
];

export const employeeItems = (t: TFunction<'translation'>) => [
  {
    subheader: t('Management'),
    items: [
      // {
      //   title: t('User'),
      //   href: PATH_DASHBOARD.company.settings,
      //   icon: ICONS.settings
      // },
      {
        title: t('My profile'),
        href: PATH_DASHBOARD.candidates.getCandidateProfileUrl('me'),
        icon: ICONS.dailyApplicants
      }
    ]
  }
];

export const recruiterItems = (t: TFunction<'translation'>, is_agency: any) => [
  {
    subheader: t('Management'),
    items: [
      {
        title: t('Dashboard'),
        href: PATH_DASHBOARD.showcase,
        icon: ICONS.dashboard,
        iconDisabled: ICONS.dashboardDis
      }
    ]
  },
  {
    subheader: t('Company'),
    items: [
      {
        title: t('Company'),
        href: PATH_DASHBOARD.company.root,
        icon: ICONS.company,
        iconDisabled: ICONS.companyDis,
        openState: false,

        items: [
          {
            title: t('Account Settings'),
            href: PATH_DASHBOARD.company.settings,
            icon: ICONS.settings
          }
          // {
          //   title: t('Site settings'),
          //   href: PATH_DASHBOARD.company.cmsSetting,
          //   icon: ICONS.settings
          // },
          // {
          //   title: t('Integrations'),
          //   href: PATH_DASHBOARD.company.integrations,
          //   icon: ICONS.settings
          // }
        ]
      }
    ]
  },
  {
    subheader: t('Culture'),
    items: [
      {
        title: t('Culture Profiles'),
        href: PATH_DASHBOARD.culture_profile.cultureProfiles,
        icon: ICONS.cultureProfileDashboard,
        iconDisabled: ICONS.cultureProfileDashboardDisable
      }
    ]
  },
  {
    subheader: t('Job Posts'),
    items: [
      {
        title: t('Job Posts'),
        href: PATH_DASHBOARD.jobPosts.root,
        icon: ICONS.jobPost,
        iconDisabled: ICONS.jobPostDis,
        openState: false
      }
    ]
  },
  {
    subheader: t('Candidates'),

    items: [
      {
        title: t('Candidates'),
        href: PATH_DASHBOARD.candidates.root,
        icon: ICONS.candidates,
        iconDisabled: ICONS.candidatesDis,

        openState: false
      }
    ]
  },
  {
    subheader: t('Calendar'),
    items: [
      {
        title: t('Calendar'),
        href: PATH_DASHBOARD.calendar.root,
        icon: ICONS.scheduleActive,
        iconDisabled: ICONS.scheduleIcon
      }
    ]
  },
  {
    subheader: t('Messenger'),
    items: [
      {
        title: t('Messenger'),
        href: PATH_DASHBOARD.messenger.root,
        icon: ICONS.messengerActiveIcon,
        iconDisabled: ICONS.messengerIcon
      }
    ]
  },
  {
    subheader: t('Data Analytics'),
    items: [
      {
        title: t('Data Analytics'),
        href: PATH_DASHBOARD.dataAnalytics.root,
        icon: ICONS.dataAnalytics,
        iconDisabled: ICONS.dataAnalyticsDis
      }
    ]
  },

  {
    subheader: t('Todos'),
    items: [
      {
        title: t('Todos'),
        href: PATH_DASHBOARD.toDoList.getmostViewedPeriod('monthly'),
        icon: ICONS.todoActiveIcon,
        iconDisabled: ICONS.todoIcon
      }
    ]
  },
  {
    subheader: t('Templates'),
    items: [
      {
        title: t('Templates'),
        href: PATH_DASHBOARD.templates.root,
        icon: ICONS.templatesActiveIcon,
        iconDisabled: ICONS.templatesIcon
      }
    ]
  },
  ...(is_agency
    ? [
        {
          subheader: t('Customers'),
          items: [
            {
              title: t('Customers'),
              href: PATH_DASHBOARD.CUSTOMERS.root,
              icon: ICONS.dataAnalytics,
              iconDisabled: ICONS.dataAnalyticsDis
            }
          ]
        }
      ]
    : [])
];

///

export const recruiterPlusItems = (
  t: TFunction<'translation'>,
  is_agency: any
) => [
  {
    subheader: t('Management'),
    items: [
      {
        title: t('Dashboard'),
        href: PATH_DASHBOARD.showcase,
        icon: ICONS.dashboard,
        iconDisabled: ICONS.dashboardDis
      }
    ]
  },
  {
    subheader: t('Company'),
    items: [
      {
        title: t('Company'),
        href: PATH_DASHBOARD.company.root,
        icon: ICONS.company,
        iconDisabled: ICONS.companyDis,
        openState: false,

        items: [
          {
            title: t('Account Settings'),
            href: PATH_DASHBOARD.company.settings,
            icon: ICONS.settings
          },
          // {
          //   title: t('Site settings'),
          //   href: PATH_DASHBOARD.company.cmsSetting,
          //   icon: ICONS.settings
          // },
          {
            title: t('Integrations'),
            href: PATH_DASHBOARD.company.integrations,
            icon: ICONS.settings
          }
        ]
      }
    ]
  },
  {
    subheader: t('Culture'),
    items: [
      {
        title: t('Culture Profiles'),
        href: PATH_DASHBOARD.culture_profile.cultureProfiles,
        icon: ICONS.cultureProfileDashboard,
        iconDisabled: ICONS.cultureProfileDashboardDisable
      }
    ]
  },
  {
    subheader: t('Job Posts'),
    items: [
      {
        title: t('Job Posts'),
        href: PATH_DASHBOARD.jobPosts.root,
        icon: ICONS.jobPost,
        iconDisabled: ICONS.jobPostDis,
        openState: false
      }
    ]
  },
  {
    subheader: t('Candidates'),

    items: [
      {
        title: t('Candidates'),
        href: PATH_DASHBOARD.candidates.root,
        icon: ICONS.candidates,
        iconDisabled: ICONS.candidatesDis,

        openState: false
      }
    ]
  },
  {
    subheader: t('Calendar'),
    items: [
      {
        title: t('Calendar'),
        href: PATH_DASHBOARD.calendar.root,
        icon: ICONS.scheduleActive,
        iconDisabled: ICONS.scheduleIcon
      }
    ]
  },
  {
    subheader: t('Messenger'),
    items: [
      {
        title: t('Messenger'),
        href: PATH_DASHBOARD.messenger.root,
        icon: ICONS.messengerActiveIcon,
        iconDisabled: ICONS.messengerIcon
      }
    ]
  },
  {
    subheader: t('Data Analytics'),
    items: [
      {
        title: t('Data Analytics'),
        href: PATH_DASHBOARD.dataAnalytics.root,
        icon: ICONS.dataAnalytics,
        iconDisabled: ICONS.dataAnalyticsDis
      }
    ]
  },

  {
    subheader: t('Todos'),
    items: [
      {
        title: t('Todos'),
        href: PATH_DASHBOARD.toDoList.getmostViewedPeriod('monthly'),
        icon: ICONS.todoActiveIcon,
        iconDisabled: ICONS.todoIcon
      }
    ]
  },
  {
    subheader: t('Templates'),
    items: [
      {
        title: t('Templates'),
        href: PATH_DASHBOARD.templates.root,
        icon: ICONS.templatesActiveIcon,
        iconDisabled: ICONS.templatesIcon
      }
    ]
  },
  ...(is_agency
    ? [
        {
          subheader: t('Customers'),
          items: [
            {
              title: t('Customers'),
              href: PATH_DASHBOARD.CUSTOMERS.root,
              icon: ICONS.dataAnalytics,
              iconDisabled: ICONS.dataAnalyticsDis
            }
          ]
        }
      ]
    : [])
];

///

export const companyIsInactiveItems = (t: TFunction<'translation'>) => [
  {
    subheader: t('Company'),
    items: [
      {
        title: t('Company'),
        href: PATH_DASHBOARD.company.root,
        icon: ICONS.company,
        iconDisabled: ICONS.companyDis,
        openState: false,

        items: [
          {
            title: t('Company settings'),
            href: PATH_DASHBOARD.company.settings,
            icon: ICONS.settings
          }
          // {
          //   title: t('Site settings'),
          //   href: PATH_DASHBOARD.company.cmsSetting,
          //   icon: ICONS.settings
          // },
          // {
          //   title: t('Integrations'),
          //   href: PATH_DASHBOARD.company.integrations,
          //   icon: ICONS.settings
          // }
        ]
      }
    ]
  }
];

///

export const hiringManagerItems = (t: TFunction<'translation'>) => [
  {
    subheader: t('Management'),
    items: [
      {
        title: t('Dashboard'),
        href: PATH_DASHBOARD.showcase,
        icon: ICONS.dashboard,
        iconDisabled: ICONS.dashboardDis
      }
    ]
  },
  {
    subheader: t('Company'),
    items: [
      {
        title: t('Company'),
        href: PATH_DASHBOARD.company.root,
        icon: ICONS.company,
        iconDisabled: ICONS.companyDis,
        openState: false,

        items: [
          {
            title: t('Company settings'),
            href: PATH_DASHBOARD.company.settings,
            icon: ICONS.settings
          }
          // {
          //   title: t('Site settings'),
          //   href: PATH_DASHBOARD.company.cmsSetting,
          //   icon: ICONS.settings
          // }
          // {
          //   title: t('Integrations'),
          //   href: PATH_DASHBOARD.company.integrations,
          //   icon: ICONS.settings
          // }
        ]
      }
    ]
  },
  {
    subheader: t('Culture'),
    items: [
      {
        title: t('Culture Profiles'),
        href: PATH_DASHBOARD.culture_profile.cultureProfiles,
        icon: ICONS.cultureProfileDashboard,
        iconDisabled: ICONS.cultureProfileDashboardDisable
      }
    ]
  },
  {
    subheader: t('Job Posts'),
    items: [
      {
        title: t('Job Posts'),
        href: PATH_DASHBOARD.jobPosts.root,
        icon: ICONS.jobPost,
        iconDisabled: ICONS.jobPostDis,
        openState: false
      }
    ]
  },
  {
    subheader: t('Candidates'),
    items: [
      {
        title: t('Candidates'),
        href: PATH_DASHBOARD.candidates.root,
        icon: ICONS.candidates,
        iconDisabled: ICONS.candidatesDis,
        openState: false
      }
    ]
  },
  {
    subheader: t('Calendar'),
    items: [
      {
        title: t('Calendar'),
        href: PATH_DASHBOARD.calendar.root,
        icon: ICONS.scheduleActive,
        iconDisabled: ICONS.scheduleIcon
      }
    ]
  },
  {
    subheader: t('Messenger'),
    items: [
      {
        title: t('Messenger'),
        href: PATH_DASHBOARD.messenger.root,
        icon: ICONS.messengerActiveIcon,
        iconDisabled: ICONS.messengerIcon
      }
    ]
  },
  {
    subheader: t('Data Analytics'),
    items: [
      {
        title: t('Data Analytics'),
        href: PATH_DASHBOARD.dataAnalytics.root,
        icon: ICONS.dataAnalytics,
        iconDisabled: ICONS.dataAnalyticsDis
      }
    ]
  },
  {
    subheader: t('Todos'),
    items: [
      {
        title: t('Todos'),
        href: PATH_DASHBOARD.toDoList.getmostViewedPeriod('monthly'),
        icon: ICONS.todoActiveIcon,
        iconDisabled: ICONS.todoIcon
      }
    ]
  },
  {
    subheader: t('Templates'),
    items: [
      {
        title: t('Templates'),
        href: PATH_DASHBOARD.templates.root,
        icon: ICONS.templatesActiveIcon,
        iconDisabled: ICONS.templatesIcon
      }
    ]
  }
];

export const reviewerItems = (t: TFunction<'translation'>) => [
  {
    subheader: t('Management'),
    items: [
      {
        title: t('Dashboard'),
        href: PATH_DASHBOARD.showcase,
        icon: ICONS.dashboard,
        iconDisabled: ICONS.dashboardDis
      }
    ]
  },
  {
    subheader: t('Culture'),
    items: [
      {
        title: t('Culture Profiles'),
        href: PATH_DASHBOARD.culture_profile.cultureProfiles,
        icon: ICONS.cultureProfileDashboard,
        iconDisabled: ICONS.cultureProfileDashboardDisable
      }
    ]
  },
  {
    subheader: t('Job Posts'),
    items: [
      {
        title: t('Job Posts'),
        href: PATH_DASHBOARD.jobPosts.root,
        icon: ICONS.jobPost,
        iconDisabled: ICONS.jobPostDis,
        openState: false
      }
    ]
  },
  {
    subheader: t('Candidates'),
    items: [
      {
        title: t('Candidates'),
        href: PATH_DASHBOARD.candidates.root,
        icon: ICONS.candidates,
        iconDisabled: ICONS.candidatesDis,
        openState: false
      }
    ]
  },
  {
    subheader: t('Messenger'),
    items: [
      {
        title: t('Messenger'),
        href: PATH_DASHBOARD.messenger.root,
        icon: ICONS.messengerActiveIcon,
        iconDisabled: ICONS.messengerIcon
      }
    ]
  }
];

export const designerItems = (t: TFunction<'translation'>) => [
  {
    subheader: t('Management'),
    items: [
      {
        title: t('Dashboard'),
        href: PATH_DASHBOARD.showcase,
        icon: ICONS.dashboard,
        iconDisabled: ICONS.dashboardDis
      }
    ]
  },
  {
    subheader: t('Company'),
    items: [
      {
        title: t('Company'),
        href: PATH_DASHBOARD.company.root,
        icon: ICONS.company,
        iconDisabled: ICONS.companyDis,
        openState: false,

        items: [
          // {
          //   title: t('Company settings'),
          //   href: PATH_DASHBOARD.company.settings,
          //   icon: ICONS.settings
          // },
          {
            title: t('Site settings'),
            href: PATH_DASHBOARD.company.cmsSetting,
            icon: ICONS.settings
          }
          // {
          //   title: t('Integrations'),
          //   href: PATH_DASHBOARD.company.integrations,
          //   icon: ICONS.settings
          // }
        ]
      }
    ]
  },
  // {
  //   subheader: t('Culture'),
  //   items: [
  //     {
  //       title: t('Culture Profiles'),
  //       href: PATH_DASHBOARD.culture_profile.cultureProfiles,
  //       icon: ICONS.cultureProfileDashboard,
  //       iconDisabled: ICONS.cultureProfileDashboardDisable
  //     }
  //   ]
  // },
  {
    subheader: t('Job Posts'),
    items: [
      {
        title: t('Job Posts'),
        href: PATH_DASHBOARD.jobPosts.root,
        icon: ICONS.jobPost,
        iconDisabled: ICONS.jobPostDis,
        openState: false
      }
    ]
  },
  {
    subheader: t('Todos'),
    items: [
      {
        title: t('Todos'),
        href: PATH_DASHBOARD.toDoList.getmostViewedPeriod('monthly'),
        icon: ICONS.todoActiveIcon,
        iconDisabled: ICONS.todoIcon
      }
    ]
  }
]; 