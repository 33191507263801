import { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Input,
  Radio,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import { PATH_DASHBOARD } from 'routes/paths';
import { useDispatch, useSelector } from 'react-redux';
import {
  createTodo,
  updateTodo,
  getToDoPostData,
  getToDos,
  editDataTodo
} from 'redux/slices/toDo';
import MButton from '../button/MButton';
import useAuth from '../../../hooks/useAuth';
import { IModalToDo } from './type';
import {
  DEFAULT_MESSAGE_TEXTS,
  statusDefault
} from '../../../constants/constants';
import { RootState } from 'redux/store';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
  label: {
    '& .MuiFormControlLabel-label': {
      fontFamily: 'Rubik',
      fontWeight: 700
    }
  }
}));

const ModalToDo = ({
  close,
  title = '',
  description = '',
  listId = '',
  type = 'daily',
  periodicity
}: IModalToDo) => {
  const { user } = useAuth();
  const { editedToDo } = useSelector((state: RootState) => state.toDo);

  const [toDoField, setToDoField] = useState({
    title,
    description,
    assignee: user.id,
    completed: editedToDo.completed,
    remind_me: editedToDo.remind_me
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const createNewToDO = async () => {
    const toDoFieldVAlues = { ...toDoField, completed: editedToDo.completed };
    if (toDoField.title != '') {
      if (listId) {
        dispatch(updateTodo(listId, toDoFieldVAlues));
      } else {
        dispatch(createTodo(toDoFieldVAlues));
        const typeValue = type ? `?type=${type}` : '';
        navigate(`${PATH_DASHBOARD.toDoList.root}${typeValue}`);
      }
      if (periodicity) dispatch(getToDoPostData(periodicity));
      enqueueSnackbar('Updated successfully', { variant: 'success' });
      close();
    } else {
      enqueueSnackbar(DEFAULT_MESSAGE_TEXTS.required, {
        variant: 'warning'
      });
    }
  };

  useEffect(() => {
    dispatch(getToDos());
  }, []);

  useEffect(() => {
    dispatch(editDataTodo({ ...editedToDo, ...toDoField }));
  }, [toDoField]);

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 590,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '20px',
        fontFamily: 'Rubik',
        height: '572px',
        padding: '40px'
      }}
    >
      <Grid container>
        <Grid
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '35px'
          }}
        >
          <Typography
            style={{ fontFamily: 'Rubik', fontSize: '20px', fontWeight: 700 }}
          >
            {t('Create new to do’s')}
          </Typography>
          <Typography onClick={close}>
            <CloseIcon
              color="primary"
              style={{ color: '#C9CAD9', cursor: 'pointer' }}
            />
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Grid sx={{ marginBottom: '15px' }}>
            <Typography
              style={{
                fontFamily: 'Rubik',
                color: '#666666',
                fontSize: '14px',
                marginBottom: '6px'
              }}
            >
              {t('Task Name')}
            </Typography>

            <input
              placeholder={t('Create a todo name')}
              className="task_fild"
              value={editedToDo.title}
              onChange={(event: any) =>
                setToDoField({ ...toDoField, title: event.target.value })
              }
            />
          </Grid>
          <Grid sx={{ marginBottom: '15px' }}>
            <Typography
              style={{
                fontFamily: 'Rubik',
                color: '#666666',
                fontSize: '14px',
                marginBottom: '6px'
              }}
            >
              {t('Description')}
            </Typography>
            <textarea
              placeholder={t('Create a todo description')}
              className="textarea_fild"
              value={editedToDo.description}
              onChange={(event: any) => {
                setToDoField({ ...toDoField, description: event.target.value });
              }}
            />
            <Typography
              style={{
                fontFamily: 'Rubik',
                color: '#666666',
                fontSize: '14px',
                marginBottom: '5px',
                marginTop: '15px'
              }}
            >
              {t('Status')}
            </Typography>
            <FormGroup
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              {statusDefault.map((item: any) => (
                <FormControlLabel
                  className={classes.label}
                  key={item.name}
                  control={<Radio color="primary" />}
                  label={item.name}
                  name={item.field}
                  checked={
                    item.field === 'completed'
                      ? editedToDo.completed
                      : !editedToDo.completed
                  }
                  style={{ color: item.color }}
                  onChange={() =>
                    setToDoField({
                      ...toDoField,
                      completed: !editedToDo.completed
                    })
                  }
                />
              ))}
            </FormGroup>
          </Grid>
          <Grid
            sx={{
              marginBottom: '25px',
              display: 'flex',
              gap: 1,
              alignItems: 'baseline'
            }}
          >
            <Typography
              style={{
                fontFamily: 'Rubik',
                color: '#666666',
                fontSize: '14px',
                marginBottom: '5px'
              }}
            >
              {t('Remind me in')}
            </Typography>
            <FormControl>
              <Input
                type="number"
                value={editedToDo.remind_me}
                onChange={(event: any) => {
                  const value = Math.min(Number(event.target.value), 999);
                  setToDoField({ ...toDoField, remind_me: value });
                }}
                sx={{ width: 40, height: 22 }}
              />
            </FormControl>
            <Typography
              style={{
                fontFamily: 'Rubik',
                color: '#666666',
                fontSize: '14px',
                marginBottom: '5px'
              }}
            >
              {t('days.')}
            </Typography>
          </Grid>
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '40px'
            }}
          >
            <MButton
              title={t('Submit')}
              click={createNewToDO}
              MyClass="violetButton"
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ModalToDo;

