import {
  DashboardDailyApplicants,
  DashboardOpenJobPosts,
  DashboardToDoPosts
} from '../../components/ShowcaseComponents';
import useLocales from '../../hooks/useLocales';
import useAuth from '../../hooks/useAuth';
import Page from '../../components/containers/Page';
import TabPanel from '../../components/TabPanel';
import DashboardNavbar from '../../layouts/dashboard/navbar/DashboardNavbar';
import PageContainer from '../../components/containers/PageContainer';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getApplicantsData,
  getJobPostData,
  getTodosData
} from 'redux/slices/showcase';
import { RootState } from 'redux/store';
import Subscription from 'components/subscription/Subscription';

const addPost = '/static/img/dashboard/addPost.svg';
const addPostActive = '/static/img/dashboard/addPostActive.svg';
const application = '/static/img/dashboard/formApp.svg';
const applicationActive = '/static/img/dashboard/formActive.svg';
const checkListTodo = '/static/img/dashboard/checklist.svg';
const checkListTodoActive = '/static/img/dashboard/checklistActive.svg';

export default function Dashboard() {
  const { t } = useLocales();
  const {
    userId,
    user,
    isAdmin,
    isDesigner,
    isRecruiter,
    isHiringManager,
    recruiterPermission,
    isReviewer,
    isRecruiterPlus
  } = useAuth();

  const canSeeTodos =
    isAdmin || isRecruiterPlus || isRecruiter || isHiringManager || isDesigner;
  const company: any = useSelector(
    (state: RootState) => state.generalRecruiter.company
  );
  const dispatch = useDispatch();

  const isShowSubscribe: boolean =
    isAdmin && !company?.onboarding_completed && company?.billing_email;

  const canViewNewApplicants = !(
    ((isRecruiter || isHiringManager) &&
      recruiterPermission === 'view_only_vacancies') ||
    isDesigner
  );

  useEffect(() => {
    if (userId && canViewNewApplicants) {
      dispatch(getJobPostData());
      dispatch(getApplicantsData());
    }
    if (canSeeTodos) {
      dispatch(getTodosData());
    }
  }, [dispatch, canViewNewApplicants, canSeeTodos]);

  const tabs = [
    {
      label: t('Open Job Posts'),
      Component: <DashboardOpenJobPosts />,
      icon: addPost,
      activeIcon: addPostActive,
      path: 'job-posts',
      hide: isDesigner
    },
    {
      label: t('New Applicants'),
      Component: <DashboardDailyApplicants />,
      icon: application,
      activeIcon: applicationActive,
      path: 'new-applicants',
      hide: !canViewNewApplicants
    },
    {
      label: t('My To Do’s'),
      Component: <DashboardToDoPosts />,
      icon: checkListTodo,
      activeIcon: checkListTodoActive,
      path: 'todos',
      hide: isReviewer
    }
  ];

  const title = `${t('Welcome back')} ${user.name}`;

  return (
    <Page title={title}>
      {isShowSubscribe && <Subscription />}
      <PageContainer>
        <DashboardNavbar title={title} />
        <TabPanel tabs={tabs} dashboard={true} />
      </PageContainer>
    </Page>
  );
}
