import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box, Checkbox, Typography } from '@material-ui/core';
import { getManagementInsights, getOpenJobPosts } from 'redux/slices/dashboard';
import { RootState } from 'redux/store';
import { PATH_DASHBOARD } from 'routes/paths';
import { deleteJobPost } from 'requests/vacancy';
import { getTranslatedList } from 'constants/constants';
import { getTexts } from 'constants/text/texts';
import useAuth from 'hooks/useAuth';
import useLocales from 'hooks/useLocales';

import { OpenJobPostCard } from 'components/CardJobPost';
import Page from 'components/containers/Page';
import PaginationContainer from 'components/containers/PaginationContainer';
import ListContainer from 'components/containers/ListContainer';
import PageContainer from 'components/containers/PageContainer';
import HeadingContainer from 'components/containers/HeadingContainer';
import {
  OverviewCheckBox,
  OverviewFilter,
  OverviewSearch
} from 'components/OverviewFilter';
import { resetJobPostsFilters, setJobPostsFilter } from 'redux/slices/filters';
import { ResetButton } from 'components/Buttons';
import {
  getCandidateAssessmentResults,
  getCandidateInfo
} from 'redux/slices/candidate';
import useURLFilters from '@hooks/useURLFilters';
import DashboardNavbar from '../../layouts/dashboard/navbar/DashboardNavbar';
import {
  FilterGroupButton,
  FilterGroupStyle,
  OverviewSelectStyle
} from '../../styled';

const JobPostsOverview = () => {
  const { t } = useLocales();
  const { id } = useParams<any>();
  const [paginationClick, setPaginationCLick] = useState<boolean>(false);
  const [checked, setChecked] = useState(false);
  const targetRef = useRef<HTMLDivElement>(null);
  const {
    openJobPosts: { results, count },
    isJobPostLoading
  } = useSelector((state: RootState) => state.dashboard);
  const {
    page,
    status,
    owner,
    periodicity,
    limit,
    offset,
    query,
    salaries,
    degrees,
    locations,
    work_exps,
    customer
  } = useSelector((state: RootState) => state.filtersReducer.openJobPosts);
  const company: any = useSelector(
    (state: RootState) => state.generalRecruiter.company
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    user,
    isCandidate,
    isAdmin,
    isRecruiterPlus,
    isRecruiter,
    recruiterPermission,
    isDesigner
  } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const {
    PERIOD_TYPES,
    STATUS_OPTIONS,
    DEFAULT_MESSAGE_TEXTS,
    DEGREE_TYPES,
    SALARY_CHOICES,
    YEAR_CHOICES,
    SALARY_CHOICES_INR
  } = getTranslatedList();
  const {
    PERIODICITY,
    STATUS,
    DEGREE,
    SALARY_RANGE,
    LOCATION,
    CUSTOMERS,
    CAREER_LEVEL
  } = getTexts();

  useEffect(() => {
    dispatch(getManagementInsights());
  }, []);

  useEffect(() => {
    if (user?.id && !isDesigner) {
      dispatch(getCandidateAssessmentResults(user.id));
    }
  }, [dispatch, user?.id]);

  useEffect(() => {
    dispatch(setJobPostsFilter('offset', page * limit - limit));
  }, [dispatch, page, limit]);

  const onDelete = async (id: string | number) => {
    try {
      await deleteJobPost(id);
      enqueueSnackbar(DEFAULT_MESSAGE_TEXTS.deleted, { variant: 'success' });
    } catch {
      enqueueSnackbar(DEFAULT_MESSAGE_TEXTS.error, { variant: 'error' });
    }
    dispatch(
      getOpenJobPosts(
        customer?.id,
        isCandidate ? 'published' : status,
        periodicity,
        limit,
        offset,
        owner,
        query,
        salaries,
        degrees,
        locations,
        work_exps
      )
    );
  };

  const onEdit = (id: string | number) => {
    navigate(PATH_DASHBOARD.jobPosts.getJobPostUrl(id));
  };

  const orderFilter = () => {
    if (checked) {
      setChecked(!checked);
      dispatch(
        getOpenJobPosts(
          customer?.id,
          isCandidate ? 'published' : status,
          periodicity,
          limit,
          offset,
          owner,
          query,
          salaries,
          degrees,
          locations,
          work_exps,
          '',
          user.candidate_id
        )
      );
    } else {
      setChecked(!checked);
      dispatch(
        getOpenJobPosts(
          customer?.id,
          isCandidate ? 'published' : status,
          periodicity,
          limit,
          offset,
          owner,
          query,
          salaries,
          degrees,
          locations,
          work_exps,
          'score',
          user.candidate_id
        )
      );
      enqueueSnackbar(DEFAULT_MESSAGE_TEXTS.filtered, { variant: 'success' });
    }
  };

  // const onCreateNew = () =>
  //   navigate(PATH_DASHBOARD.jobPosts.getJobPostUrl('new'));

  const title = t('Job Posts Overview');
  useEffect(() => {
    if (!isCandidate) {
      dispatch(
        getOpenJobPosts(
          customer?.id,
          status,
          periodicity,
          limit,
          offset,
          owner,
          query,
          salaries,
          degrees,
          locations,
          work_exps,
          id ? 'score' : '',
          id || null,
          id ? 50 : 0
        )
      );
    } else if (user?.candidate_id) {
      dispatch(
        getOpenJobPosts(
          '',
          'published',
          periodicity,
          limit,
          offset,
          owner,
          query,
          salaries,
          degrees,
          locations,
          work_exps,
          '',
          user.candidate_id
        )
      );
    }
  }, [
    dispatch,
    customer,
    status,
    periodicity,
    limit,
    offset,
    owner,
    query,
    salaries,
    degrees,
    locations,
    work_exps
  ]);

  useEffect(() => {
    if (id) {
      dispatch(getCandidateInfo(id));
    }
  }, [id]);

  useEffect(() => {
    if (paginationClick && targetRef.current && !isJobPostLoading) {
      targetRef.current.scrollIntoView({
        block: 'start'
      });
    }
  }, [results, isJobPostLoading, page, paginationClick]);

  useEffect(() => {
    if (isJobPostLoading === false) {
      setPaginationCLick(false);
    }
  }, [isJobPostLoading]);

  const filterNames = [
    'limit',
    'offset',
    'status',
    'periodicity',
    'limit',
    'offset',
    'owner',
    'q',
    'salaries',
    'degrees',
    'locations',
    'work_exps'
  ];

  const currentFilters = useSelector(
    (state: RootState) => state.filtersReducer.openJobPosts
  );

  useURLFilters({
    fragmentIdentifier: '#all/',
    filterNames,
    setterFunction: setJobPostsFilter,
    currentFilters
  });

  const hasPublishedByMeFilter =
    isAdmin ||
    ((isRecruiterPlus || isRecruiter) && recruiterPermission === 'view_all');

  return (
    <Page title={title}>
      <PageContainer
        style={{
          padding: 0
        }}
      >
        <DashboardNavbar title={title} />
        <HeadingContainer subtitle={t('Your job posts')} />
        <FilterGroupStyle>
          <OverviewSelectStyle
            title={DEGREE}
            placeholder={t('Filter by degree')}
            activeOption={degrees}
            options={DEGREE_TYPES}
            setOption={(value: any) =>
              dispatch(setJobPostsFilter('degrees', value))
            }
          />
          <OverviewSelectStyle
            title={`${SALARY_RANGE} (${company.currency})`}
            placeholder={t(`Filter by salary range`)}
            activeOption={salaries}
            options={
              company.currency == 'INR' ? SALARY_CHOICES_INR : SALARY_CHOICES
            }
            setOption={(value: any) =>
              dispatch(setJobPostsFilter('salaries', value))
            }
          />
          {company.is_agency && (
            <OverviewSelectStyle
              title={CUSTOMERS}
              type="select-customers"
              placeholder={t('Filter by customer')}
              activeOption={customer}
              setOption={(value: any) =>
                dispatch(setJobPostsFilter('customer', value))
              }
              isPlaceholder={true}
            />
          )}
          <OverviewSelectStyle
            title={LOCATION}
            type="location"
            placeholder={t('Filter by location')}
            setOption={(value: any) =>
              dispatch(setJobPostsFilter('locations', value))
            }
            isPlaceholder={true}
          />
          <OverviewSelectStyle
            title={CAREER_LEVEL}
            placeholder={t('Filter by career level')}
            activeOption={work_exps}
            options={YEAR_CHOICES}
            setOption={(value: any) =>
              dispatch(setJobPostsFilter('work_exps', value))
            }
          />
          {hasPublishedByMeFilter && (
            <OverviewCheckBox
              title={t('Published by me')}
              activeOption={owner}
              options={{ on: 'me', off: 'all' }}
              setOption={(value) => dispatch(setJobPostsFilter('owner', value))}
            />
          )}
        </FilterGroupStyle>
        <FilterGroupButton
          style={{
            columnGap: '50px',
            alignItems: 'end'
          }}
        >
          <OverviewFilter
            title={PERIODICITY}
            activeOption={periodicity}
            options={PERIOD_TYPES}
            setOption={(value) =>
              dispatch(setJobPostsFilter('periodicity', value))
            }
          />

          {!isCandidate && (
            <OverviewFilter
              title={STATUS}
              activeOption={status}
              options={STATUS_OPTIONS}
              setOption={(value) =>
                dispatch(setJobPostsFilter('status', value))
              }
            />
          )}
          <OverviewSearch
            style={{
              minWidth: '270px'
            }}
            title={' '}
            placeholder={t('Search by Name')}
            setValue={useCallback(
              (value: any) => dispatch(setJobPostsFilter('query', value)),
              [dispatch]
            )}
            value={query}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              pb: 2,
              cursor: 'pointer'
            }}
            onClick={() => dispatch(resetJobPostsFilters())}
          >
            <Typography
              ref={targetRef}
              style={{
                fontFamily: 'Rubik',
                fontWeight: 700,
                fontSize: '18px',
                color: '#562C82'
              }}
            >
              {t('Reset All')}
            </Typography>
            <ResetButton />
          </Box>
          {isCandidate && (
            <Box sx={{ display: 'flex', alignItems: 'flex-end', pb: 2 }}>
              <span className="filter_label">Show best matches</span>
              <Checkbox
                checked={checked}
                onChange={orderFilter}
                color="primary"
              />
            </Box>
          )}
        </FilterGroupButton>
        <ListContainer
          loading={isJobPostLoading}
          list={results}
          card={OpenJobPostCard}
          getCardProps={(item: any) => ({
            editable: !isCandidate,
            onDelete: () => onDelete(item.id),
            onEdit: () => onEdit(item.id)
          })}
          gridProps={{ md: 6, lg: 4 }}
        />
        <PaginationContainer
          page={page}
          count={count}
          perPage={limit}
          setPage={(value) => {
            setPaginationCLick(true);
            dispatch(setJobPostsFilter('page', value));
          }}
        />
      </PageContainer>
    </Page>
  );
};

export default JobPostsOverview;
